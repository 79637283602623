import * as React from 'react'
import * as yup from 'yup'
import {
  Button,
  Card,
  CardProps,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { useForm, useFormContext, useWatch } from 'react-hook-form'
import { Form, SelectForm } from 'shared/forms'
import { ListIncomePayload } from '../data/Queries'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateNumberList } from 'core/utils'
import clsx from 'clsx'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { Organization } from 'modules/organization/models/Organization'
import { useConstant } from 'shared/hooks'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { Company, CompanyType } from 'modules/company/models/Company'
import { CategoryMoveAutocomplete } from 'modules/category-move/components/CategoryMoveAutocomplete'
import {
  CategoryMove,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import {
  nonBankPaymentMethods,
  PaymentMethod,
  paymentMethods,
} from '../models/Income'

export interface FilterProps extends CardProps {
  loading?: boolean
  onFilter: (payload: ListIncomePayload, values: FilterForm) => void
}

export interface FilterForm {
  organization?: Organization
  bankAccount?: BankAccount
  employee?: Company
  category?: CategoryMove
  year: number
  month: number
  paymentMethod: PaymentMethod | 'all'
}

const schema = yup.object().shape({
  organization: yup.object().nullable(),
  bankAccount: yup.object().nullable(),
  employee: yup.object().nullable(),
  category: yup.object().nullable(),
  year: yup.number().required('Debe seleccionar un año'),
  month: yup.number().required('Debe seleccionar un mes'),
  paymentMethod: yup.string().isOptional('all'),
})

const baseYear = 2021

const years = generateNumberList(dayjs().year() - baseYear + 1).map(
  n => n + baseYear,
)

const buildFilter = ({
  organization,
  bankAccount,
  employee,
  category,
  paymentMethod,
  year,
  month,
}: FilterForm): ListIncomePayload => {
  const monthDate = dayjs(`${month + 1}/${year}`, 'M/YYYY')

  return {
    paymentMethod: paymentMethod === 'all' ? undefined : paymentMethod,
    organizationId: organization?.id,
    bankAccountId: bankAccount?.id,
    employeeId: employee?.id,
    categoryId: category?.id,
    initDate: monthDate.startOf('month').toDate(),
    endDate: monthDate.endOf('month').toDate(),
  }
}

export const Filter = ({
  onFilter,
  className,
  loading,
  ...props
}: FilterProps) => {
  const now = dayjs()

  const defaultValues = useConstant<FilterForm>(() => ({
    year: now.year(),
    month: now.month(),
    paymentMethod: 'all',
    organization: null,
    bankAccount: null,
    employee: null,
    category: null,
  }))

  const formInstance = useForm<FilterForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  React.useEffect(() => {
    onFilter(buildFilter(defaultValues), defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const onSubmit = (values: FilterForm) => {
    onFilter(buildFilter(values), values)
  }

  return (
    <Card {...props} className={clsx('flex flex-row items-center', className)}>
      <Typography className="flex-none" variant="body1">
        Filtrar listado
      </Typography>
      <Form
        formProps={{ className: 'flex-grow ml-48 flex flex-row items-center' }}
        onSubmit={onSubmit}
        {...formInstance}
      >
        <Grid container spacing={3}>
          <Grid container item xs={10} spacing={3}>
            <Grid item xs={3}>
              <SelectForm
                name="year"
                label="Seleccionar año"
                fullWidth
                disabled={loading}
                required
              >
                {years.map(year => {
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                })}
              </SelectForm>
              <OrganizationAutocomplete
                name="organization"
                inputProps={{ label: 'Organización' }}
                placeholder="Buscar organización..."
                fullWidth
                disabled={loading}
              ></OrganizationAutocomplete>
            </Grid>
            <Grid item xs={3}>
              <SelectForm
                name="month"
                label="Seleccionar mes"
                fullWidth
                disabled={loading}
                required
              >
                {dayjs.months().map((month, index) => {
                  return (
                    <MenuItem key={month} value={index}>
                      {month}
                    </MenuItem>
                  )
                })}
              </SelectForm>
              <BankAccountAutocomplete
                name="bankAccount"
                inputProps={{ label: 'Cuenta de banco' }}
                placeholder="Buscar cuenta de banco"
                fullWidth
              ></BankAccountAutocomplete>
            </Grid>
            <Grid item xs={3}>
              <CompanyAutocomplete
                name="employee"
                type={[CompanyType.employee]}
                inputProps={{ label: 'Empleado' }}
                placeholder="Buscar empleado"
                fullWidth
              ></CompanyAutocomplete>
              <CategoryMoveAutocomplete
                name="category"
                type={MoveType.income}
                inputProps={{ label: 'Categoría' }}
                placeholder="Buscar categoría"
                className="mb-16"
                fullWidth
              ></CategoryMoveAutocomplete>
            </Grid>
            <Grid item xs={3}>
              <PaymentMethodForm></PaymentMethodForm>
            </Grid>
          </Grid>
          <Grid item xs={2} className="flex items-center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  )
}

function PaymentMethodForm() {
  const { control } = useFormContext<FilterForm>()
  const bankAccount = useWatch<BankAccount | null>({
    control,
    name: 'bankAccount',
    defaultValue: control.getValues('bankAccount'),
  })

  React.useEffect(() => {
    const paymentMethod = control.getValues('paymentMethod')
    if (paymentMethod === 'all') {
      return
    }

    if (bankAccount && nonBankPaymentMethods.includes(paymentMethod)) {
      control.setValue('paymentMethod', 'all')
    }
  }, [bankAccount, control])

  return (
    <SelectForm
      name="paymentMethod"
      label="Método de pago"
      className="mb-16"
      fullWidth
    >
      <MenuItem value="all">
        <em>Seleccionar método de pago</em>
      </MenuItem>
      {paymentMethods.map(pm => (
        <MenuItem
          key={pm.value}
          value={pm.value}
          disabled={bankAccount && nonBankPaymentMethods.includes(pm.value)}
        >
          {pm.text}
        </MenuItem>
      ))}
    </SelectForm>
  )
}
