import { gql, useMutation } from '@apollo/client'
import { Income, PaymentMethod } from '../models/Income'
import { ListIncomeFragment } from './Queries'
import { apolloClient } from 'core/GqlClient'

export interface RegisterIncomePayload {
  date: Date
  /** YYYY-DD-MM string */
  dateOnly?: string
  amount: number
  notes?: string
  clientId: string
  employeeId?: string
  categoryId: string
  organizationId: string
  paymentMethod: PaymentMethod
  bankAccountId?: string
  hasTicket: boolean
  hasInvoice: boolean
  issueDate?: Date
  dueDate?: Date
  code?: string
  SATPaymentMethodId?: string
  concept?: string
  subtotal?: number
  iva?: number
  payment?: RegisterDebtPaymentPayload
  customerCreditId?: string
}

export class RegisterDebtPaymentPayload {
  debtId: string
  capital?: number
  interest?: number
  otherAmount?: number
}

const REGISTER_INCOME = gql`
  mutation CreateIncome($data: RegisterIncomePayload!) {
    registerIncome(data: $data) {
      ...ListIncomeFragment
    }
  }
  ${ListIncomeFragment}
`

export const useRegisterIncomeMutation = () => {
  const tuple = useMutation<
    { registerIncome: Income },
    { data: RegisterIncomePayload }
  >(REGISTER_INCOME)

  return tuple
}

export const registerIncome = async (data: RegisterIncomePayload) => {
  const result = await apolloClient.mutate<{ registerIncome: Income }>({
    mutation: REGISTER_INCOME,
    variables: { data },
  })

  return result.data.registerIncome
}

const DELETE_INCOME = gql`
  mutation DeleteIncome($id: String!) {
    deleteIncome(id: $id) {
      id
      status
    }
  }
`

export const useDeleteIncomeMutation = () => {
  const tuple = useMutation<{ deleteIncome: Income }, { id: string }>(
    DELETE_INCOME,
  )

  return tuple
}

export interface UpdateIncomePayload {
  id: string
  clientId?: string
  employeeId?: string
  categoryId?: string
}

const UPDATE_INCOME = gql`
  mutation UpdateIncome($data: UpdateIncomePayload!) {
    updateIncome(data: $data) {
      id
      company {
        id
        name
      }
      employee {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`

export const useUpdateIncomeMutation = () => {
  return useMutation<{ updateIncome: Income }, { data: UpdateIncomePayload }>(
    UPDATE_INCOME,
  )
}
