import { WithPermissions } from 'auth/components/WithPermissions'
import { Route } from 'react-router-dom'
import { useCrudContext } from './CrudContext'

export interface ActionPageProps {
  action: string
  permissions: string[]
  children: React.ReactNode
}

export const ActionPage = ({
  action,
  permissions,
  children,
}: ActionPageProps) => {
  const { url } = useCrudContext()
  return (
    <WithPermissions permissions={permissions}>
      <Route path={`${url}/${action}`}>{children}</Route>
    </WithPermissions>
  )
}
