import { parseDate } from 'core/utils'
import { TenantModel } from 'models/TenantModel'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CategoryMove } from 'modules/category-move/models/CategoryMove'
import { Company } from 'modules/company/models/Company'
import { Organization } from 'modules/organization/models/Organization'
import { Invoice } from './Invoice'
import { Ticket } from './Ticket'
import { Credit } from 'modules/credit/models/Credit'

export enum PaymentMethod {
  cash = 'cash',
  transfer = 'transfer',
  deposit = 'deposit',
  check = 'check',
  credit_card = 'credit_card',
  debit_card = 'debit_card',
  customer_credit = 'customer_credit',
}

export const nonBankPaymentMethods = [
  PaymentMethod.cash,
  PaymentMethod.customer_credit,
]

export const incomePaymentMethodsLabels = {
  [PaymentMethod.cash]: 'Efectivo',
  [PaymentMethod.transfer]: 'Transferencia',
  [PaymentMethod.deposit]: 'Depósito',
  [PaymentMethod.check]: 'Cheque',
  [PaymentMethod.credit_card]: 'Tarjeta de crédito',
  [PaymentMethod.debit_card]: 'Tarjeta de débito',
  [PaymentMethod.customer_credit]: 'Crédito clientes',
}

export const paymentMethods: {
  text: string
  value: PaymentMethod
}[] = Object.keys(incomePaymentMethodsLabels).map(key => ({
  text: incomePaymentMethodsLabels[key as PaymentMethod],
  value: key as PaymentMethod,
}))

export class Income extends TenantModel {
  date: Date
  amount: number
  company: Company
  employee?: Company
  paymentMethod: PaymentMethod
  category: CategoryMove
  organization: Organization
  bankAccount: BankAccount
  notes?: string
  invoice?: Invoice
  ticket?: Ticket
  customerCredit?: Credit

  constructor(data: Partial<Income>) {
    super(data)
    const {
      date,
      company,
      category,
      organization,
      bankAccount,
      ...income
    } = data

    if (date) {
      this.date = parseDate(date)
    }

    if (company) {
      this.company = new Company(company)
    }

    if (category) {
      this.category = new CategoryMove(category)
    }

    if (organization) {
      this.organization = new Organization(organization)
    }

    if (bankAccount) {
      this.bankAccount = new BankAccount(bankAccount)
    }

    Object.assign(this, income)
  }
}
