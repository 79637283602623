import * as React from 'react'
import styled from '@emotion/styled'
import { Link, List, ListItem, ListItemText } from '@material-ui/core'
import {
  Accept,
  DropEvent,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone/.'
import { useControllableProp } from 'shared/hooks'

export interface FileInputZoneProps {
  className?: string
  maxFiles: number
  accept: Accept
  files?: FileWithPath[]
  onDropAccepted: (
    files: FileWithPath[],
    event: React.DragEvent<HTMLDivElement>,
  ) => void
}

interface ContainerProps {
  isDragAccept: boolean
  isDragReject: boolean
  isFocused: boolean
}

const getColor = (props: ContainerProps) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const FileInputZone = (props: FileInputZoneProps) => {
  const [filesState, setFiles] = React.useState<FileWithPath[]>([])
  const [isControlled, files] = useControllableProp(props.files, filesState)
  const urls = React.useRef<Record<string, string>>({})
  const onDrop = (
    acceptedFiles: FileWithPath[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
  ) => {
    acceptedFiles.forEach(file => {
      urls.current[file.name] = URL.createObjectURL(file)
    })
    if (!isControlled) {
      setFiles(acceptedFiles)
    }
  }

  const {
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: props.accept,
    onDropAccepted: props.onDropAccepted,
    maxFiles: props.maxFiles,
  })

  React.useEffect(() => {
    const localUrls = Object.keys(urls.current)
    return () => {
      localUrls.forEach(url => {
        URL.revokeObjectURL(localUrls[url])
      })
    }
  }, [])

  return (
    <section className={`container ${props.className}`}>
      <Container
        {...getRootProps({
          isDragAccept,
          isDragReject,
          isFocused,
          className: 'dropzone cursor-pointer',
        })}
      >
        <input {...getInputProps()} />
        <p>Arrastra archivos aquí, o haz clic para seleccionar archivos</p>
        {props.maxFiles > 1 ? (
          <em>({props} archivos máximos permitidos)</em>
        ) : null}
      </Container>
      <aside>
        <List dense>
          {files.map(file => (
            <ListItem key={file.path}>
              <Link href={urls.current[file.name]} download>
                <ListItemText primary={file.name} />
              </Link>
            </ListItem>
          ))}
        </List>
      </aside>
    </section>
  )
}
