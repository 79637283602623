import dayjs from 'dayjs'
import * as React from 'react'
import { useBankAccountList } from 'modules/bank-account/data/Queries'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { useCategoryMoveList } from 'modules/category-move/data/Queries'
import {
  CategoryMove,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import { useCompanyList } from 'modules/company/data/Queries'
import { Company } from 'modules/company/models/Company'
import { useOrganizationList } from 'modules/organization/data/Queries'
import { Organization } from 'modules/organization/models/Organization'
import * as yup from 'yup'
import { outcomeCompanyTypes, PaymentMethod } from '../models/Outcome'
import { registerOutcome } from './Mutations'

export interface OutcomeRelatedData {
  companies: Company[]
  categories: CategoryMove[]
  bankAccounts: BankAccount[]
  organizations: Organization[]
}

export const useOutcomeRelatedData = () => {
  const companiesQuery = useCompanyList({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        companyType: outcomeCompanyTypes,
      },
    },
  })
  const categoriesQuery = useCategoryMoveList({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        moveType: MoveType.outcome,
        onlyChilds: true,
      },
    },
  })
  const organizationsQuery = useOrganizationList({
    fetchPolicy: 'network-only',
  })
  const bankAccountsQuery = useBankAccountList({
    fetchPolicy: 'network-only',
  })

  const companies = companiesQuery.data?.listCompany ?? []
  const categories = categoriesQuery.data?.listCategoryMove ?? []
  const organizations = organizationsQuery.data?.listOrganization ?? []

  const bankAccounts = bankAccountsQuery.data?.listBankAccount ?? []

  const loading =
    companiesQuery.loading ||
    categoriesQuery.loading ||
    bankAccountsQuery.loading ||
    organizationsQuery.loading

  return {
    loading,
    companies,
    categories,
    bankAccounts,
    organizations,
  }
}

export interface OutcomeBulkCreateData {
  date: string
  company: Company
  category: CategoryMove
  organization: Organization
  amount: number
  paymentMethod: PaymentMethod
  bankAccount: BankAccount
  notes: string
}

const schema = yup.object().shape({
  date: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'La fecha no es válida')
    .required('La fecha del egreso es requerida'),
  company: yup
    .object()
    .nullable()
    .required('El Proveedor/Empleado/Acreedor del egreso es requerido'),
  category: yup
    .object()
    .nullable()
    .required('La categoría del egreso es requerida'),
  organization: yup
    .object()
    .nullable()
    .required('La organización del egreso es requerida'),
  amount: yup
    .number()
    .nullable()
    .moreThan(0, 'El monto del egreso debe ser mayor a 0')
    .required('El monto del egreso es requerido'),
  paymentMethod: yup
    .string()
    .oneOf(Object.values(PaymentMethod), 'El método de pago no es correcto')
    .required('El método de pago es requerido'),
  bankAccount: yup.object().when('paymentMethod', {
    is: paymentMethod =>
      ![
        PaymentMethod.cash,
        PaymentMethod.credit,
        PaymentMethod.credit_card,
      ].includes(paymentMethod),
    then: yup
      .object()
      .nullable()
      .required('La cuenta bancaria es requerida para este método de pago'),
  }),
  notes: yup.string().isOptional(),
})

export const useOutcomeBulkCreate = () => {
  const [loading, setLoading] = React.useState(false)
  const [progress, setProgress] = React.useState<{
    progress: number
    total: number
  }>({ progress: 0, total: 0 })

  const bulkCreate = async (data: OutcomeBulkCreateData[]) => {
    try {
      setLoading(true)
      setProgress({ progress: 0, total: data.length })

      for (let index = 0; index < data.length; index++) {
        const item = data[index]
        await schema.validate(item)
        await registerOutcome({
          isPayment: false,
          outcome: {
            date: dayjs(item.date, 'YYYY-MM-DD').toDate(),
            dateOnly: item.date,
            amount: item.amount,
            notes: item.notes,
            paymentMethod: item.paymentMethod,
            companyId: item.company.id,
            categoryId: item.category.id,
            organizationId: item.organization.id,
            bankAccountId: item.bankAccount?.id,
            hasTaxReceipt: false,
            isDirectDebit: false,
          },
        })
        setProgress(value => ({
          progress: value.progress + 1,
          total: value.total,
        }))
      }
    } finally {
      setLoading(false)
    }
  }

  const reset = () => {
    setLoading(false)
    setProgress({ progress: 0, total: 0 })
  }

  return {
    loading,
    ...progress,
    bulkCreate,
    reset,
  }
}
