import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link,
} from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { FileInputZone } from 'shared/common/FileInputZone'
import {
  OutcomeBulkCreateData,
  useOutcomeBulkCreate,
  useOutcomeRelatedData,
} from '../data/BulkCreate'
import readXlsxFile, { CellValue } from 'read-excel-file'
import dayjs from 'dayjs'
import { outcomePaymentMethodsOptions } from '../models/Outcome'
import { useSnackbar } from 'notistack'
import { ValidationError } from 'yup'
import utcPlugin from 'dayjs/plugin/utc'

dayjs.extend(utcPlugin)

enum ImportColumns {
  date = 0,
  company = 1,
  category = 2,
  organization = 3,
  amount = 4,
  paymentMethod = 5,
  bankAccount = 6,
  notes = 7,
}

const dialogId = 'import-outcomes-dialog'
export const Import = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [file, setFile] = React.useState<File | null>(null)
  const history = useHistory()
  const snackbar = useSnackbar()
  const outcomeRelatedData = useOutcomeRelatedData()
  const { loading, progress, total, bulkCreate } = useOutcomeBulkCreate()

  const showError = (message: string) => {
    snackbar.enqueueSnackbar(message, { variant: 'error' })
  }

  const showSuccess = (message: string) => {
    snackbar.enqueueSnackbar(message, { variant: 'success' })
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push(`/outcomes`)
  }

  const onFilesAccepted = (files: File[]) => {
    setFile(files[0])
  }

  const parseDate = (date: CellValue) => {
    if (!date) {
      return null
    }
    if (date instanceof Date) {
      return dayjs.utc(date).format('YYYY-MM-DD')
    }
    if (typeof date === 'string') {
      return dayjs.utc(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
    return null
  }

  const onSubmit = async () => {
    const rows = await readXlsxFile(file)
    const rawData = rows.slice(1)
    const data = rawData.map<OutcomeBulkCreateData>(row => {
      const date = parseDate(row[ImportColumns.date])
      const company = outcomeRelatedData.companies.find(
        c => c.name === row[ImportColumns.company]?.toString().trim(),
      )
      const category = outcomeRelatedData.categories.find(
        c => c.name === row[ImportColumns.category]?.toString().trim(),
      )
      const organization = outcomeRelatedData.organizations.find(
        o => o.name === row[ImportColumns.organization]?.toString().trim(),
      )
      let amount: number | undefined
      try {
        amount = parseFloat(row[ImportColumns.amount]?.toString())
        if (isNaN(amount)) {
          amount = undefined
        }
      } catch (error) {
        amount = undefined
      }
      const paymentMethod = outcomePaymentMethodsOptions.find(
        p => p.text === row[ImportColumns.paymentMethod]?.toString().trim(),
      )?.value
      const bankAccount = outcomeRelatedData.bankAccounts.find(
        b => b.name === row[ImportColumns.bankAccount]?.toString().trim(),
      )
      const notes = row[ImportColumns.notes]?.toString()
      return {
        date,
        company,
        category,
        organization,
        amount,
        paymentMethod,
        bankAccount,
        notes,
      }
    })
    if (!data.length) {
      showError('No se encontraron datos para importar')
      return
    }
    try {
      await bulkCreate(data)
      showSuccess('Los egresos se importaron correctamente')
      onClose()
    } catch (error) {
      console.warn(error)
      if (error instanceof ValidationError) {
        console.warn(error.value)
        showError(error.message)
      } else {
        showError('Ocurrió un error al importar los datos')
      }
    }
  }

  const disabled = outcomeRelatedData.loading || loading || !file
  const percent = total ? (progress / total) * 100 : 0

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEnforceFocus
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Importar egresos</DialogTitle>
      <DialogContent>
        <div>
          Para importar egresos, descargue el{' '}
          <Link
            href="/assets/import/outcomes.xlsx"
            download="Importar Egresos - Ejemplo"
          >
            archivo de ejemplo
          </Link>
          , complételo y vuelva a subirlo.
          <FileInputZone
            className="mt-5"
            maxFiles={1}
            accept={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                '.xlsx',
              ],
            }}
            files={file ? [file] : []}
            onDropAccepted={onFilesAccepted}
          ></FileInputZone>
        </div>
        {percent ? (
          <div className="flex flex-row items-center mt-5">
            <LinearProgress
              className="flex-grow"
              variant="determinate"
              value={percent}
            />
            <p className="flex-none ml-5 text-right" style={{ width: '140px' }}>
              {progress} / {total} ({percent.toFixed(2)}%)
            </p>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={onSubmit}
          disabled={disabled}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
