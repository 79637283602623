import { CrudPage } from 'shared/crud/CrudPage'
import { AddPage } from 'shared/crud/CrudPage/AddPage'
import { EditPage } from 'shared/crud/CrudPage/EditPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { Create } from './components/Create'
import { List } from './components/List'
import { Update } from './components/Update'
import { ActionPage } from 'shared/crud/CrudPage/ActionPage'
import { Import } from './components/Import'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { CloudDownload } from '@material-ui/icons'

export const IncomePages = () => {
  return (
    <CrudPage
      name="income"
      actions={['list', 'create', 'update']}
      includePaths={url => [`${url}/import`]}
    >
      <ListPage
        title="Listado de ingresos"
        actions={
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/incomes/import"
          >
            <CloudDownload className="mr-5"></CloudDownload> Importar
          </Button>
        }
      >
        <List></List>
      </ListPage>
      <AddPage>
        <Create></Create>
      </AddPage>
      <EditPage>
        <Update></Update>
      </EditPage>
      <ActionPage action="import" permissions={['income.create']}>
        <Import></Import>
      </ActionPage>
    </CrudPage>
  )
}
