import dayjs from 'dayjs'
import * as React from 'react'
import { useBankAccountList } from 'modules/bank-account/data/Queries'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { useCategoryMoveList } from 'modules/category-move/data/Queries'
import {
  CategoryMove,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import { useCompanyList } from 'modules/company/data/Queries'
import { Company, CompanyType } from 'modules/company/models/Company'
import { useOrganizationList } from 'modules/organization/data/Queries'
import { Organization } from 'modules/organization/models/Organization'
import * as yup from 'yup'
import { nonBankPaymentMethods, PaymentMethod } from '../models/Income'
import { registerIncome } from './Mutations'

export interface IncomeRelatedData {
  customers: Company[]
  categories: CategoryMove[]
  bankAccounts: BankAccount[]
  organizations: Organization[]
}

export const useIncomeRelatedData = () => {
  const customersQuery = useCompanyList({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        companyType: [CompanyType.customer],
      },
    },
  })

  const categoriesQuery = useCategoryMoveList({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        moveType: MoveType.income,
        onlyChilds: true,
      },
    },
  })

  const bankAccountsQuery = useBankAccountList({
    fetchPolicy: 'network-only',
  })

  const organizationsQuery = useOrganizationList({
    fetchPolicy: 'network-only',
  })

  const customers = customersQuery.data?.listCompany ?? []
  const categories = categoriesQuery.data?.listCategoryMove ?? []
  const bankAccounts = bankAccountsQuery.data?.listBankAccount ?? []
  const organizations = organizationsQuery.data?.listOrganization ?? []

  const loading =
    customersQuery.loading ||
    categoriesQuery.loading ||
    bankAccountsQuery.loading ||
    organizationsQuery.loading

  return {
    loading,
    customers,
    categories,
    bankAccounts,
    organizations,
  }
}

export interface IncomeBulkCreateData {
  date: string
  customer: Company
  category: CategoryMove
  organization: Organization
  amount: number
  paymentMethod: PaymentMethod
  bankAccount: BankAccount
  notes: string
}

const schema = yup.object().shape({
  date: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'La fecha no es válida')
    .required('La fecha del ingreso es requerida'),
  customer: yup
    .object()
    .nullable()
    .required('El cliente del ingreso es requerido'),
  category: yup
    .object()
    .nullable()
    .required('La categoría del ingreso es requerida'),
  organization: yup
    .object()
    .nullable()
    .required('La organización del ingreso es requerida'),
  amount: yup
    .number()
    .nullable()
    .moreThan(0, 'El monto del ingreso debe ser mayor a 0')
    .required('El monto del ingreso es requerido'),
  paymentMethod: yup
    .string()
    .oneOf(Object.values(PaymentMethod), 'El método de pago no es correcto')
    .required('El método de pago es requerido'),
  bankAccount: yup.object().when('paymentMethod', {
    is: paymentMethod => !nonBankPaymentMethods.includes(paymentMethod),
    then: yup
      .object()
      .nullable()
      .required('La cuenta bancaria es requerida para este método de pago'),
  }),
  notes: yup.string().isOptional(),
})

export const useIncomeBulkCreate = () => {
  const [loading, setLoading] = React.useState(false)
  const [progress, setProgress] = React.useState<{
    progress: number
    total: number
  }>({ progress: 0, total: 0 })

  const bulkCreate = async (data: IncomeBulkCreateData[]) => {
    try {
      setLoading(true)
      setProgress({ progress: 0, total: data.length })

      for (let index = 0; index < data.length; index++) {
        const item = data[index]
        await schema.validate(item)
        await registerIncome({
          date: dayjs(item.date, 'YYYY-MM-DD').toDate(),
          dateOnly: item.date,
          amount: item.amount,
          notes: item.notes,
          paymentMethod: item.paymentMethod,
          clientId: item.customer.id,
          categoryId: item.category.id,
          organizationId: item.organization.id,
          bankAccountId: item.bankAccount?.id,
          hasTicket: false,
          hasInvoice: false,
        })
        setProgress(value => ({
          progress: value.progress + 1,
          total: value.total,
        }))
      }
    } finally {
      setLoading(false)
    }
  }

  const reset = () => {
    setLoading(false)
    setProgress({ progress: 0, total: 0 })
  }

  return {
    loading,
    ...progress,
    bulkCreate,
    reset,
  }
}
