import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import {
  AccountBalance,
  ListAccountBalancePayload,
  useAccountBalanceList,
} from './data/Queries'
import { Column } from 'react-table'
import { formatCurrency } from 'shared/utils'
import { Card, Typography } from '@material-ui/core'
import { Filter } from './Filter'
import { SimplePage } from 'shared'
import { AccountBalanceDetail } from './Detail'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from 'shared/crud/ExportCsvButton'
import {
  Credit,
  CreditKind,
  creditKindLabels,
} from 'modules/credit/models/Credit'
import { AccountType } from 'modules/account/models/Account'
import { getCreditCompanyTypes } from 'modules/credit/utils/CompanyType'
import { companyTypeLabels } from 'modules/company/models/Company'

function getCreditColumnText(credit: Credit) {
  if (credit.kind !== CreditKind.creditor) {
    return `Crédito ${creditKindLabels[credit.kind]}`
  }
  return 'Crédito'
}

function getAccountName(account: AccountBalance) {
  if (account.creditCard) {
    return account.creditCard.name
  }
  if (account.bankAccount) {
    return account.bankAccount.name
  }
  if (account.credit) {
    return account.credit.name
  }
  return account.organization?.name
}

export const AccountBalanceReport = () => {
  const [getList, { data: listData, loading }] = useAccountBalanceList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const [filter, setFilter] = React.useState<ListAccountBalancePayload>()

  const { initDate, endDate, accountType, creditKind } = filter || {}

  const data = React.useMemo(() => {
    return listData?.listAccountBalance ?? []
  }, [listData])

  const columns = React.useMemo(() => {
    let cols: Column<AccountBalance>[] = []
    cols.push({
      id: 'account.type',
      Header: 'Tipo de Cuenta',
      accessor: ({ creditCard, bankAccount, credit }) => {
        if (creditCard) {
          return 'Tarjeta de crédito'
        }
        if (bankAccount) {
          return 'Cuenta de banco'
        }
        if (credit) {
          return getCreditColumnText(credit)
        }
        return 'Caja'
      },
    })

    if (accountType === AccountType.credit) {
      const companyTypes = getCreditCompanyTypes(creditKind)
      const creditCompanyHeader = companyTypes
        .map(type => companyTypeLabels[type])
        .join(' / ')
      cols.push({
        id: 'credit.company.name',
        Header: creditCompanyHeader,
        accessor: ({ credit }) => credit?.company?.name,
      })
    }

    cols = cols.concat([
      {
        id: 'account.name',
        Header: 'Cuenta',
        accessor: balance => {
          return getAccountName(balance)
        },
      },
      {
        Header: 'Saldo Inicial',
        accessor: 'initialBalance',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Depósitos',
        accessor: 'income',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Transferencias recibidas',
        accessor: 'transferIncome',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Cargos',
        accessor: 'outcome',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Transferencias enviadas',
        accessor: 'transferOutcome',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Saldo final',
        accessor: 'balance',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        disableSortBy: true,
        id: 'actions',
        Header: 'Acciones',
        accessor: balance => (
          <AccountBalanceDetail
            balance={balance}
            balances={data}
            accountName={getAccountName}
            initDate={initDate}
            endDate={endDate}
          ></AccountBalanceDetail>
        ),
      },
    ])
    return cols
  }, [initDate, endDate, accountType, creditKind, data])

  const tableInstance = useDataTable({
    selectable: false,
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<AccountBalance>>(() => {
    let cols: ExportCsvButtonProps<AccountBalance>['columns'] = [
      {
        id: 'account.type',
        header: 'Tipo de cuenta',
        cell: row => {
          if (row.creditCard) {
            return 'Tarjeta de crédito'
          }
          if (row.bankAccount) {
            return 'Cuenta de banco'
          }
          if (row.credit) {
            return getCreditColumnText(row.credit)
          }
          return 'Caja'
        },
      },
    ]

    if (accountType === AccountType.credit) {
      const companyTypes = getCreditCompanyTypes(creditKind)
      const creditCompanyHeader = companyTypes
        .map(type => companyTypeLabels[type])
        .join(' / ')
      cols.push({
        id: 'credit.company.name',
        header: creditCompanyHeader,
        cell: row => row.credit?.company?.name,
      })
    }

    cols = cols.concat([
      {
        id: 'account.name',
        header: 'Cuenta',
        cell: row => {
          if (row.creditCard) {
            return row.creditCard.name
          }
          if (row.bankAccount) {
            return row.bankAccount.name
          }
          if (row.credit) {
            return row.credit.name
          }
          return row.organization?.name
        },
      },
      {
        id: 'initialBalance',
        header: 'Saldo Inicial',
        cell: row => row.initialBalance,
      },
      {
        id: 'income',
        header: 'Depósitos',
        cell: row => row.income,
      },
      {
        id: 'transferIncome',
        header: 'Transferencias recibidas',
        cell: row => row.transferIncome,
      },
      {
        id: 'outcome',
        header: 'Cargos',
        cell: row => row.outcome,
      },
      {
        id: 'transferOutcome',
        header: 'Transferencias enviadas',
        cell: row => row.transferOutcome,
      },
      {
        id: 'balance',
        header: 'Saldo Final',
        cell: row => row.balance,
      },
    ])

    return {
      columns: cols,
      filename: `Reporte de saldos - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data, creditKind, accountType])

  const toolBarContent = () => {
    return <ExportCsvButton {...csvProps}></ExportCsvButton>
  }

  const onFilter = (payload: ListAccountBalancePayload) => {
    setFilter(payload)
    getList({
      variables: {
        data: payload,
      },
    })
  }

  return (
    <SimplePage
      header={
        <div className="flex h-full w-full py-24 px-40 items-center justify-between">
          <div>
            <Typography variant="h6">Reporte de saldos</Typography>
          </div>
        </div>
      }
    >
      <div className="p-32">
        <Filter
          loading={loading}
          className="my-14 p-20"
          onFilter={onFilter}
        ></Filter>
        <Card>
          <DataTable
            toolBarContent={toolBarContent}
            {...tableInstance}
          ></DataTable>
        </Card>
      </div>
    </SimplePage>
  )
}
