import * as React from 'react'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { DataTable, useDataTable } from 'shared/common'
import { useDisclosure } from 'shared/hooks'
import { AccountBalance, useAccountBalanceDetail } from './data/Queries'
import { Column } from 'react-table'
import { AccountMove } from 'modules/account/models/AccountMove'
import { formatCurrency } from 'shared/utils'
import dayjs from 'dayjs'
import { MoveType } from 'modules/category-move/models/CategoryMove'
import { incomePaymentMethodsLabels } from 'modules/income/models/Income'
import { outcomePaymentMethodsLabels } from 'modules/outcome/models/Outcome'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from 'shared/crud/ExportCsvButton'

export interface AccountBalanceDetailProps {
  balance: AccountBalance
  balances: AccountBalance[]
  accountName: (balance: AccountBalance) => string
  initDate: Date
  endDate: Date
}

interface AccountBalanceWithName extends AccountBalance {
  name: string
}

const dialogId = 'ShowAccountBalanceDetail'

const useStyles = makeStyles({
  notesField: {
    maxHeight: 60,
    overflowY: 'auto',
    whiteSpace: 'break-spaces',
    width: 200,
  },
})

const getMoveTransactionType = (
  accountMove: AccountMove,
): 'income' | 'outcome' | 'unknown' => {
  if (accountMove.income) {
    return 'income'
  }
  if (accountMove.outcome) {
    return 'outcome'
  }
  return null
}

const getMoveTransaction = (accountMove: AccountMove) => {
  if (accountMove.income) {
    return accountMove.income
  }
  if (accountMove.outcome) {
    return accountMove.outcome
  }
  return null
}

const getPaymentMethod = (accountMove: AccountMove) => {
  const type = getMoveTransactionType(accountMove)
  const moveTransaction = getMoveTransaction(accountMove)
  const paymentMethod = moveTransaction?.paymentMethod
  if (!paymentMethod) {
    return ''
  }

  if (type === 'income') {
    return incomePaymentMethodsLabels[paymentMethod]
  }

  if (type === 'outcome') {
    return outcomePaymentMethodsLabels[paymentMethod]
  }

  return ''
}

const getCompany = (
  accountMove: AccountMove,
  accountMap: Record<string, AccountBalanceWithName>,
) => {
  const moveTransaction = getMoveTransaction(accountMove)
  if (moveTransaction) {
    return moveTransaction.company.name
  }
  // Transfer
  const sourcedTransfer = accountMove.sourcedTransfer
  if (sourcedTransfer) {
    const target = sourcedTransfer.target
    return accountMap[target.account.id].name
  }

  const targetTransfer = accountMove.targetedTransfer
  if (targetTransfer) {
    const source = targetTransfer.source
    return accountMap[source.account.id].name
  }

  return ''
}

const getCategory = (accountMove: AccountMove) => {
  const moveTransaction = getMoveTransaction(accountMove)
  if (moveTransaction) {
    return moveTransaction.category.name
  }
  // Transfer
  const sourcedTransfer = accountMove.sourcedTransfer
  if (sourcedTransfer) {
    return 'Transferencia enviada'
  }

  const targetTransfer = accountMove.targetedTransfer
  if (targetTransfer) {
    return 'Transferencia recibida'
  }

  return ''
}

export const AccountBalanceDetail = ({
  balance,
  balances,
  accountName,
  initDate,
  endDate,
}: AccountBalanceDetailProps) => {
  const classes = useStyles()

  const [getList, { data: listData }] = useAccountBalanceDetail({
    fetchPolicy: 'network-only',
  })

  const accountId = balance.id
  const { isOpen, open, close } = useDisclosure({
    onOpen: () => {
      getList({
        variables: {
          account: accountId,
          data: {
            initDate,
            endDate,
          },
        },
      })
    },
  })

  const accountMap = React.useMemo(() => {
    return balances.reduce<Record<string, AccountBalanceWithName>>(
      (acc, balance) => {
        acc[balance.id] = {
          ...balance,
          name: accountName(balance),
        }
        return acc
      },
      {},
    )
  }, [balances, accountName])

  const columns = React.useMemo<Column<AccountMove>[]>(() => {
    return [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        id: 'company',
        Header: 'Compañía',
        accessor: accountMove => getCompany(accountMove, accountMap),
      },
      {
        id: 'category',
        Header: 'Categoría',
        accessor: accountMove => getCategory(accountMove),
      },
      {
        id: 'paymentMethod',
        Header: 'Método de pago',
        accessor: accountMove => getPaymentMethod(accountMove),
      },
      {
        id: 'notes',
        Header: 'Notas',
        accessor: ({ notes }) => <p className={classes.notesField}>{notes}</p>,
      },
      {
        Header: 'Saldo Inicial',
        accessor: 'initialBalance',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        id: 'outcome',
        Header: 'Cargo',
        accessor: ({ amount, moveType }) =>
          moveType === MoveType.outcome ? amount : 0,
        Cell: ({ value }) => (value ? formatCurrency(value) : ''),
      },
      {
        id: 'income',
        Header: 'Depósito',
        accessor: ({ amount, moveType }) =>
          moveType === MoveType.income ? amount : 0,
        Cell: ({ value }) => (value ? formatCurrency(value) : ''),
      },
      {
        Header: 'Saldo final',
        accessor: 'balance',
        Cell: ({ value }) => formatCurrency(value),
      },
    ]
  }, [classes, accountMap])

  const data = React.useMemo(() => {
    return listData?.listAccountBalanceDetail ?? []
  }, [listData])

  const tableInstance = useDataTable({
    selectable: false,
    columns,
    data,
  })

  const balanceName = accountName(balance)

  const csvProps = React.useMemo<ExportCsvButtonProps<AccountMove>>(() => {
    return {
      columns: [
        {
          id: 'date',
          header: 'Fecha',
          cell: ({ date }) => dayjs(date).format('L'),
        },
        {
          id: 'company',
          header: 'Compañía',
          cell: row => getCompany(row, accountMap),
        },
        {
          id: 'category',
          header: 'Categoría',
          cell: row => getCategory(row),
        },
        {
          id: 'paymentMethod',
          header: 'Método de pago',
          cell: row => getPaymentMethod(row),
        },
        {
          id: 'notes',
          header: 'Notas',
          multiline: true,
          cell: ({ notes }) => notes,
        },
        {
          id: 'initialBalance',
          header: 'Saldo Inicial',
          cell: ({ initialBalance }) => initialBalance,
        },
        {
          id: 'outcome',
          header: 'Cargo',
          cell: ({ amount, moveType }) =>
            moveType === MoveType.outcome ? amount : 0,
        },
        {
          id: 'income',
          header: 'Depósito',
          cell: ({ amount, moveType }) =>
            moveType === MoveType.income ? amount : 0,
        },
        {
          id: 'balance',
          header: 'Saldo final',
          cell: ({ balance }) => balance,
        },
      ],
      filename: `Movimientos de cuenta - ${balanceName} - ${dayjs().format(
        'L hh:mm:ss',
      )}`,
      data,
    }
  }, [data, balanceName, accountMap])

  const toolBarContent = () => {
    return <ExportCsvButton {...csvProps}></ExportCsvButton>
  }

  return (
    <>
      <Tooltip title="Mostrar listado de movimientos">
        <IconButton aria-label="detalle" onClick={open}>
          <VisibilityIcon></VisibilityIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Listado de movimientos</DialogTitle>
        <DialogContent>
          <DataTable
            toolBarContent={toolBarContent}
            {...tableInstance}
          ></DataTable>
        </DialogContent>
      </Dialog>
    </>
  )
}
